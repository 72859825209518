const en_US = {
    MyGallery: 'My Gallery',
    MyBrands: 'My Brands',
    MyActivities: 'My Activities',
    MyLiked: 'My Liked',
    UnnamedUser: "Unnamed User",
    Listed: "Listed",
    Unlisted: "Unlisted",
    CopySuccessful: 'Copy Successful',
    TryAgain: 'Hmm. You hit a glitch. Sorry for the trouble. Please try again.',
    "Code-1": 'Please Connet your wallet or sign again.',
    "Re-sign": "Please sign again",
    "ConnectWallet": "Please connect your wallet",

    Category: {
        "Category": "Category",
        "All": "All",
        "Image": "Image",
        "Video": "Video",
    },
    
    userUserInfo: {
        "uploadSuccess": "Information upload success!",
        "OnlySupports": "Only Supports JPG, PNG, JPEG2000",
    },

    ErrorMsg: {
        "required": "This field is required and cannot be blank",
        "regular": "This is not in conformity with the standard, please fill in again",
        "email": "Please enter a valid email address",
        "url": "Please enter a valid address",
        "intNum": "Please enter a positive integer",
    },

    header: {
        home: 'Home',
        marketplace: 'MarketPlace',
        brands: 'Brands',
        create: 'Create',
        connect: 'Connect Wallet',
        setting: 'Account Settings',
        search: 'Search',
        search_p: 'Search objects, items, art',
        SelectBSC: "Please select BSC network.",
        SwitchToBSC: "Click here to switch to BSC network.",
    },

    'pages.buy.Share': 'Share',
    'pages.buy.Like': 'Like',
    'pages.buy.Canceled': 'Canceled',
    'pages.buy.OwnedBy': 'Owned by',
    'pages.buy.TokenContractAddress': 'Token Contract Address',
    'pages.buy.TokenSymbol': 'Token Symbol',
    'pages.buy.TokenID': 'Token ID',
    'pages.buy.CurrentPrice': 'Current price',
    'pages.buy.TopBid': 'Top Bid',
    'pages.buy.TotalAmount': 'Total Amount',
    'pages.buy.ThisTrandingClosed': 'This Tranding Closed',
    'pages.buy.Close': 'Close',
    'pages.buy.Failed': 'Failed',
    'pages.buy.SoldOut': 'Sold Out',
    'pages.buy.PlaceABid': 'Place a bid',
    'pages.buy.BuyNow': 'Buy Now',
    'pages.buy.BuyNowFor': 'Buy now for',
    'pages.buy.Offers': 'Offers',
    'pages.buy.Supply': 'Supply',
    'pages.buy.TokenInfo': 'TokenInfo',
    'pages.buy.ExternalLink': 'External link',
    'pages.buy.TradingHistory': 'Trading History',
    'pages.buy.MarketPlace': 'MarketPlace',
    'pages.buy.FineArts': 'Fine Arts',
    'pages.buy.descriptionLoading': 'Description Is Loading ...',
    'pages.buy.NameLoading': 'Name Is Loading ...',
    'pages.buy.loading': 'loading ...',
    'pages.buy.TryAgain': 'Hmm. You hit a glitch. Sorry for the trouble. Please try again.',
    'pages.buy.DataUpdateFailed': 'Data update failed, please try again',
    'pages.buy.DealFailed': 'Deal failed, claim back NFT',
    'pages.buy.SuccessfullyClaim': "Deal failed, You have successfully Claim NFT",
    'pages.buy.ClaimBidNFT': 'Claim Bid NFT',
    'pages.buy.BidAndClaimed': 'You have successfully bid and claimed',
    'pages.buy.Cancel': 'Cancel',
    'pages.buy.ConfirmCancel': 'Confirm Cancel',
    'pages.buy.AreYouSure': 'Are you sure to cancel?',
    'pages.buy.Yes': 'Yes',
    'pages.buy.No': 'No',
    'pages.buy.TransactionSuccess': 'The transaction has been successfully completed！',
    'pages.buy.AuctionCancelled': 'The auction bill can only be cancelled when it expires',

    'pages.buy.TradeTable.Event': 'Event',
    'pages.buy.TradeTable.Quantity': 'Quantity',
    'pages.buy.TradeTable.Price': 'Price',
    'pages.buy.TradeTable.From': 'From',
    'pages.buy.TradeTable.To': 'To',
    'pages.buy.TradeTable.Date': 'Date',
    'pages.buy.TradeTable.Buy': 'Buy',
    'pages.buy.TradeTable.Bid': 'Bid',
    'pages.buy.TradeTable.Transfer': 'Transfer',
    'pages.buy.TradeTable.Created': 'Created',
    'pages.buy.TradeTable.Listed': 'Listed',

    "UIKit.Input.Upload.infoTip.FormatIncorrect": 'The file format you selected is incorrect',
    "UIKit.Input.Upload.infoTip.uploadImage": 'upload Image',
    "UIKit.Input.Upload.infoTip.uploadVideo": 'upload Video',
    "UIKit.Input.Upload.infoTip.requirement1": 'Supports JPG, PNG, JPEG2000',
    "UIKit.Input.Upload.infoTip.requirement2": 'no more than 100MB, 262*262 Reccomended',
    "UIKit.Input.Upload.infoTip.image.requirement1": 'Supports JPG, PNG, JPEG2000',
    "UIKit.Input.Upload.infoTip.image.requirement2": 'no more than 100MB, 262*262 Reccomended',
    "UIKit.Input.Upload.infoTip.video.requirement1": 'Supports MP4',
    "UIKit.Input.Upload.infoTip.video.requirement2": 'no more than 100MB, 360*240 Reccomended',
    "UIKit.Input.Upload.infoTip.avatar.ChangeProfilePhoto": 'Change Profile Photo',
    "UIKit.Input.Upload.infoTip.avatar.requirement1": 'Supports JPG, PNG, JPEG2000',
    "UIKit.Input.Upload.infoTip.avatar.requirement2": 'no more than 100MB, 262*262 Reccomended',
    "UIKit.Input.Upload.infoTip.videoError": 'Oops!File size or file type is wrong. Please check and try again.',
    "UIKit.Input.Upload.infoTip.uploadImageAndVideo": 'upload Image Or Video',
    "UIKit.Input.Upload.infoTip.requirementImageANdVideo": 'Supports JPG, PNG, JPEG2000 ,MP4',
    "UIKit.Input.Upload.infoTip.image.requirementImageAndVideo": 'Image no more than 100MB, 262*262 Reccomended. Video no more than 100MB, 360*240 Reccomended',

    home: {
        banner1: 'We make it easy to trade in creativity.',
        banner2: 'For fans, artists and collectors.',
        Explore: 'Explore',
        fast: 'Fast movers',
        brand: 'Name droppers',
        footerBanner: 'Create your unique NFT on Fangible',
        more: 'Load More',
        view: 'View All',
        cardTitle1: 'Marketplace',
        cardContent1: 'A full colour marketplace of art, comics, sports and collectibles',
        cardTitle2: 'Brands',
        cardContent2: 'Discover our artists, movers, makers and creators',

        "PopularItem.LoadingPrice": "Loading price ...",
        "PopularItem.Price": "Price",
        "PopularItem.TopBid": "Top Bid",
    },

    market: {
        myMarket: 'My Market',
        fineArts: 'Fine Arts',
        sports: 'Sports',
        comics: 'Comics',
        placeholder: "Search Items and Accounts",
        Marketplace: "Marketplace",
    },

    MarketPlace: {
        "CardItem.LoadingPrice": "Loading Price",
        "CardItem.Price": "Price",
        "CardItem.TopBid": "Top Bid",
        "CardItem.ShowMore": "Show More",
        "CardItem.PlayVideo": "Play Video",
        "CardItem.ListenAudio": "Listen Audio",
    },

    Factory: {
        /* wrapperIntl("Factory.GenerateYourNFT") */
        "Generate": "Generate",
        "Build": "Build",
        "List": "List",
        "GenerateYourNFT": "Generate your NFT",
        "GenerateDescription": "You don’t need a store or brand to get started. Generate NFTs and go from there.",
        "BuildYourBrand": "Build your brand",
        "BuildDescription": "You can create a contract and produce unlimited amount of NFTs under your own contract.",
        "ListYourNFT": "List your NFT",
        "ListDescription1": "If you already have a token contract set up and you just want to set up a space to sell them, use our get-listed flow instead.",
        "ListDescription2": "Only for live on mainnet.",
    },

    AirHome: {
        placeholder: "Search items，Brands and Accounts",
        fineArts: 'FineArts',
        sports: 'Sports',
        comics: 'Comics',
        Change: "Change",
    },

    Brands: {
        placeholder: "Search Brand Name or Brand Creator",
        "BrandCard.Ownedby": "Owned by",
        "BrandCard.VisitStore": "Visit Store",
    },

    ListNFTModal: {
        "ListYourNFT": "List Your NFT",
        "WhatAddress": "What is the address of your ERC721 or ERC1155 contract on the Ethereum Network?",
        "placeholder": "Enter your ERC721 or ERC1155  contract address",
        "Cancel": "Cancel",
        "Submit": "Submit",
        "LoadItems": "Load Items",
        "step1": "Enter your contract address",
        "step2": "List your Items",
    },

    TransferStatusModal: {
        "title1": "Bounce Requests Approval",
        "content1": "Please enable Bounce to access your tokens",
        "title2": "Waiting for confirmation",
        "content2": "Confirm this transaction in your wallet",
        "title3": "Loading",
        "content3": "Please wait a little",
        "title4": "Your BOT was Staked",
        "content4": "You can claim your principle",
        "title5": "Your BOT was Unstaked",
        "content5": "You successfully unstake your token",
        "title-1": "Transaction Failed",
        "content-1": "Your transaction was cancelled and wasn’t submitted",
        "title-2": "Canceling your pool creation",
        "content-2": "You cancel your pool creation",
        /* "title0": "",
        "content0": "", */
        "title6": "Success!",
        "content6": "You successfully claim your reward",
        "title7": "successfully!",
        "content7": "The transaction has been successfully completed!",
        "Awaiting": "Awaiting...",
        "Close": "Close...",
        "TryAgain": "Try again",
    },

    PlaceBidModal: {
        "EnterAmount": "Enter Amount",
        "Cancel": "Cancel",
        "SaleEndsIn": "Sale ends in",
        "days": "days",
        "hours": "hours",
        "minutes": "minutes",
        "PlaceAbid": "Place a bid",
        "Checkout": "Checkout",
        "BuyNow": "Buy Now",
    },

    BuyNowModal: {
        "YourBalance": "Your Balance",
        "NameLoading": "Name Is Loading ...",
        "Cancel": "Cancel",
        "Checkout": "Checkout",
    },

    FixedSwapBuyModal: {
        "BuyAmount": "Buy Amount",
        "Cancel": "Cancel",
        "Checkout": "Checkout",
    },

    ConnectWalletModal: {
        "ConnectWallet": "Connect to a wallet",
        "PleaseWait": "Please wait a little...",
        "ToGetStarted": "To get started, connect a wallet. Select an option below or hook in via your Metamask. Don’t have a clue what we’re talking about? Don’t worry. Click",
        "here": "here",
        "ToLearn": "to learn.",
    },

    TableList: {
        "YourInformation": "Your information",
        "ContractAddress": "Contract Address",
        "ContractName": "Contract Name",
        "ContactSymbol": "Contact Symbol",
        "TotalSupply": "Total Supply",
    }, 

    MyProfile: {
        CommonHeader: {
            MyGallery: 'My Gallery',
            MyBrands: 'My Brands',
            MyLiked: 'My Liked',
            MyActivities: 'My Activities',
            Settings: 'Settings',
            setting: 'Account Settings',
            Change: "Change",
        },

        AddCardItem: {
            AddNewNFT: '+ Add new NFT',
        },

        Category: {
            Items: 'Items',
            Status: 'Status',
        },

        "CardItem.CheckStatus": "Check Status",
        "CardItem.MakeUnlisted": "Make Unlisted",
        "CardItem.Sell": "Sell",
        "CardItem.All": "All",
        "CardItem.OnSale": "On Sale",
        "CardItem.NotOnSale": "Not OnSale",
        "CardItem.TopBid": "Top Bid",
        "CardItem.Price": "Price",
        "CardItem.InProcessOfCreation": "In process of creation ...",
        "CardItem.WaitingBlock": "Waiting for block confirmation ...",

        "SettingAccountModal.Save": "Save",
        "SettingAccountModal.MyAccountSettings": "My Account Settings",
        "SettingAccountModal.UploadingFile": "Uploading File ...",
        "SettingAccountModal.UploadingData": "Uploading Data ...",
        "SettingAccountModal.OnlySupports": "Only supports JPG, PNG, JPEG2000",
        "SettingAccountModal.UserName": "User Name",
        "SettingAccountModal.FullName": "Full Name",
        "SettingAccountModal.BounceID": "Bounce ID",
        "SettingAccountModal.Email": "Email (Optional)",
        "SettingAccountModal.EmailPlaceholder": "Enter your email",
        "SettingAccountModal.ShortBio": "Short Bio (Optional)",
        "SettingAccountModal.ShortBioPlaceholder": "Describe your bio",
        "SettingAccountModal.Cancel": "Cancel",
        "SettingAccountModal.DataUpdateFailed": 'Data update failed, please try again',

        'MyGallery.GenerateNewNFTModal.GenerateNewNFT': 'Generate New NFT',
        'MyGallery.GenerateNewNFTModal.Name': 'Name',
        'MyGallery.GenerateNewNFTModal.Category': 'Category',
        'MyGallery.GenerateNewNFTModal.Channel': 'Channel',
        'MyGallery.GenerateNewNFTModal.Standard': 'Standard',
        'MyGallery.GenerateNewNFTModal.Description': 'Description',
        'MyGallery.GenerateNewNFTModal.RequiredAlert': 'RequiredAlert',
        'MyGallery.GenerateNewNFTModal.browseBrandPhoto': 'browse Brand Photo',
        'MyGallery.GenerateNewNFTModal.Cancel': 'Cancel',
        'MyGallery.GenerateNewNFTModal.Submit': 'Submit',
        'MyGallery.GenerateNewNFTModal.Supply': 'Supply',
        'MyGallery.GenerateNewNFTModal.TryAgain': 'Try Again',
        'MyGallery.GenerateNewNFTModal.UploadingData': 'Uploading Data...',
        'MyGallery.GenerateNewNFTModal.SuccessfullyGenerate': "Congratulations. Your NFTs have been generated.",
        'MyGallery.GenerateNewNFTModal.TryAgainNotice': 'Hmm. You hit a glitch. Sorry for the trouble. Please try again.',
        
        "MyGallery.MyNFT.DataUpdateFailed": 'Data update failed, please try again',

        /* wrapperIntl('MyProfile.MyGallery.SellNFT.EnglishAuction') */
        'MyGallery.SellNFT.MyGallery': 'My Gallery',
        'MyGallery.SellNFT.ItemName': 'Item name',
        'MyGallery.SellNFT.Sell': 'Sell',
        'MyGallery.SellNFT.Price': 'Price',
        'MyGallery.SellNFT.PriceNotice': "Will be on sale until you transfer this item or cancel it.",
        'MyGallery.SellNFT.Instructions1': "Bounce Collectible is decentralized, so we never escrow your items. As a result, if this is your first time selling a crypto collectible, you need to complete 2 free (plus gas) transactions:",
        'MyGallery.SellNFT.Instructions2': "To initialize your account for making sell orders, which only needs to be done once for your account.",
        'MyGallery.SellNFT.Instructions3': "To allow Bounce Collectible to access your item (or all items in the collection, if the collection supports it) when a sale occurs.",
        'MyGallery.SellNFT.Minimumbid': 'Minimum bid',
        'MyGallery.SellNFT.MinimumbidNotice': "The price bidding starts at. It'll be publicly visible. You can manually accept bids above this value but below your reserve price if you want.",
        'MyGallery.SellNFT.DirectPurchasePrice': 'Direct purchase price',
        'MyGallery.SellNFT.DirectPurchaseNotice': "A direct transaction price can be set, that is, users can skip the bidding process and buy directly at this price. The direct transaction price must be greater than the Minimum bid and Reserve price.",
        'MyGallery.SellNFT.ReservePrice': 'Reserve price',
        'MyGallery.SellNFT.ReservePriceNotice': "Setting a reserve price creates a hidden limit,If you receive no bids equal to or greater than your reserve,your auction will not be sold.",
        'MyGallery.SellNFT.ExprirationDate': 'Expriration Date',
        'MyGallery.SellNFT.ExprirationDateNotice': 'Your auction will automatically end at this time and the highest bidder will win. No need to cancel it!',
        'MyGallery.SellNFT.SelectMethod': 'Select your sell method',
        'MyGallery.SellNFT.SetPriceFeature': "Claim your value and set the price you know you’re worth",
        'MyGallery.SellNFT.EnglishAcutionFeature': "Sell to the highest bidder for the selected period of time",
        'MyGallery.SellNFT.InstantSale': "Instant sale",
        'MyGallery.SellNFT.EnglishAuction': "English Auction",
        
        /* wrapperIntl('MyProfile.MyGallery.Summary.ToFangible') */
        'MyGallery.Summary.Summary': "Summary",
        'MyGallery.Summary.Listing': "Listing",
        'MyGallery.Summary.setPriceListingText': "Your item will be listed for",
        'MyGallery.Summary.EnglishAuctionListingText': "Your item will be auctioned. The highest bidder will win it as long as their bid is at least",
        'MyGallery.Summary.days': "days",
        'MyGallery.Summary.Fees': "Fees",
        'MyGallery.Summary.ToFangible': "To Fangible",
        'MyGallery.Summary.PostYourListing': "Post your Listing",
        'MyGallery.Summary.ExpirationDate': "Expiration Date",

        'MyGallery.InputPrice.Price': "Price",
        'MyGallery.InputPrice.Amount': "Amount",
        'MyGallery.InputPrice.Balance': "Balance",

        'MyGallery.InstructionsDropdown.Instructions': "Instructions",

        'MyGallery.DurationDropdown.str1': "In",
        'MyGallery.DurationDropdown.str2': "days",

        'MyBrands.ListCardItem.Create': "Create",

        /* wrapperIntl('MyProfile.MyBrands.updateTopBarImg.uploadImage') */
        'MyBrands.updateTopBarImg.ChangeThemePhoto': "Change Theme Photo",
        'MyBrands.updateTopBarImg.infoTip1': "Supports JPG, PNG, JPEG2000",
        'MyBrands.updateTopBarImg.infoTip2': "no more than 100MB,",
        'MyBrands.updateTopBarImg.infoTip3': "Reccomended",
        'MyBrands.updateTopBarImg.ApplyAndSave': "Apply and Save",
        'MyBrands.updateTopBarImg.UploadingFile': "Uploading File ...",
        'MyBrands.updateTopBarImg.UploadError': "Upload Error!",
        'MyBrands.updateTopBarImg.uploadImage': "upload Image",
        'MyBrands.updateTopBarImg.Reselect': "Reselect",
        'MyBrands.updateTopBarImg.preview': "preview",
        'MyBrands.updateTopBarImg.CropAgain': "Crop again",

        "MyBrands.AddNewBrandsModal.Save": "Save",
        "MyBrands.AddNewBrandsModal.Cancel": "Cancel",
        "MyBrands.AddNewBrandsModal.UploadingFile": "Uploading File ...",
        "MyBrands.AddNewBrandsModal.UploadingData": "Uploading Data ...",
        "MyBrands.AddNewBrandsModal.TryAgain": "Try Again",
        "MyBrands.AddNewBrandsModal.BuildYourBrand": "Build Your Brand",
        "MyBrands.AddNewBrandsModal.BrandName": "Brand Name",
        "MyBrands.AddNewBrandsModal.Standard": "Standard",
        "MyBrands.AddNewBrandsModal.StandardNotice": "Select a Standard for the created Brand,which is not changed,and the new Item under BRAN will also cast this SRANDARD.",
        "MyBrands.AddNewBrandsModal.Symbol": "Symbol",
        "MyBrands.AddNewBrandsModal.Description": "Description",
        "MyBrands.AddNewBrandsModal.DescriptionPlaceHolder": "Describe your brand",
        "MyBrands.AddNewBrandsModal.browseBrandPhoto": 'browse Brand Photo',
        "MyBrands.AddNewBrandsModal.OnlySupports": "Only supports JPG, PNG, JPEG2000",
        "MyBrands.AddNewBrandsModal.SuccessfullyBuild": "You have successfully build your brands",
        "MyBrands.AddNewBrandsModal.DataUpdateFailed": 'Data update failed, please try again',

        /* wrapperIntl('MyProfile.MyBrands.AddNewItemModal.Cancel') */
        "MyBrands.AddNewItemModal.OnlySupports": "Only supports JPG, PNG, JPEG2000",
        'MyBrands.AddNewItemModal.SuccessfullyGenerate': "Congratulations. Your NFTs have been generated.",
        'MyBrands.AddNewItemModal.TryAgainNotice': 'Hmm. You hit a glitch. Sorry for the trouble. Please try again.',
        "MyBrands.AddNewItemModal.UploadingData": "Uploading Data ...",
        "MyBrands.AddNewItemModal.Submit": "Submit",
        "MyBrands.AddNewItemModal.AddNewItem": "Add New Item",
        "MyBrands.AddNewItemModal.Name": "Name",
        "MyBrands.AddNewItemModal.Category": "Category",
        "MyBrands.AddNewItemModal.Channel": "Channel",
        "MyBrands.AddNewItemModal.Standard": "Standard",
        "MyBrands.AddNewItemModal.Supply": "Supply",
        "MyBrands.AddNewItemModal.Description": "Description",
        "MyBrands.AddNewItemModal.browseBrandPhoto": "browse Brand Photo",
        "MyBrands.AddNewItemModal.Cancel": "Cancel",

        "MyBrands.CardItem.Add": "Add",

        "MyBrands.BrandsByType.AllItems": "All Items",
        "MyBrands.BrandsByType.Image": "Image",
        "MyBrands.BrandsByType.BackToBrands": "Back to Brands",
        "MyBrands.BrandsByType.Change": "Change",
        "MyBrands.BrandsByType.Edit": "Edit",
        "MyBrands.BrandsByType.EditYourBrand": "Edit your Brand",
        "MyBrands.BrandsByType.BrandName": "Brand Name",
        "MyBrands.BrandsByType.Description": "Description",
        "MyBrands.BrandsByType.DescribeYourBrand": "Describe your brand",
        "MyBrands.BrandsByType.Cancel": "Cancel",

        /* wrapperIntl("MyProfile.MyActivities.TableItem.Transfer") */
        "MyActivities.TableList.Event": "Event",
        "MyActivities.TableList.Item": "Item",
        "MyActivities.TableList.Quantity": "Quantity",
        "MyActivities.TableList.From": "From",
        "MyActivities.TableList.To": "To",
        "MyActivities.TableList.Date": "Date",
        "MyActivities.TableItem.Transfer": "Transfer",
        "MyActivities.TableItem.Created": "Created",
        "MyActivities.TableItem.about": "about",
        "MyActivities.TableItem.minutes": "minutes",
        "MyActivities.TableItem.minute": "minute",
        "MyActivities.TableItem.hours": "hours",
        "MyActivities.TableItem.hour": "hour",
        "MyActivities.TableItem.days": "days",
        "MyActivities.TableItem.day": "day",
    },
}

export default en_US