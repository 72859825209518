export const getEllipsisAddress = (address) => {
  return address.substring(0, 4) + '...' + address.slice(-4);
}



// export const getMetadata = (json) => {
//   try {
//     return json ? JSON.parse(json).miniImg : {miniImg: ''}
//   } catch (error) {
//     return { miniImg: ''}
//   }
// }