const zh_CN = {
    MyGallery: '我的展厅',
    MyBrands: '我的品牌',
    MyActivities: '我的活动记录',
    MyLiked: '我的收藏',
    UnnamedUser: "未命名用户",
    Listed: "在售",
    Unlisted: "非卖品",
    CopySuccessful: '复制成功',
    TryAgain: '非常抱歉，我们似乎碰到了一些小问题，请重试',
    "Code-1": '请连接钱包或重新签名',
    "Re-sign": "请重新签名",
    "ConnectWallet": "请连接钱包",

    Category: {
        "Category": "格式",
        "All": "全部",
        "Image": "图像",
        "Video": "视频",
    },

    userUserInfo: {
        "uploadSuccess": "信息上传成功！",
        "OnlySupports": "仅支持 JPG, PNG, JPEG2000 格式",
    },

    ErrorMsg: {
        "required": "此项不可为空",
        "regular": "您的输入不符合规则，请重新尝试",
        "email": "请输入正确的邮箱地址",
        "url": "请输入正确的链接",
        "intNum": "请输入正整数",
    },

    header: {
        home: '首页',
        marketplace: '市场',
        brands: '品牌',
        create: '创造',
        connect: '连接钱包',
        setting: '账户设置',
        search: '搜索',
        search_p: '搜索用户、品牌、NFT',
        SelectBSC: "请连接BSC网络，",
        SwitchToBSC: "点击此处切换至BSC网络",
    },

    'pages.buy.Share': '分享',
    'pages.buy.Like': '收藏',
    'pages.buy.Canceled': '已取消',
    'pages.buy.OwnedBy': '卖方',
    'pages.buy.TokenContractAddress': '合约地址',
    'pages.buy.TokenSymbol': '凭证类型',
    'pages.buy.TokenID': '凭证',
    'pages.buy.CurrentPrice': '当前价格',
    'pages.buy.TopBid': '最高出价',
    'pages.buy.TotalAmount': '总量',
    'pages.buy.ThisTrandingClosed': '交易关闭',
    'pages.buy.Close': '关闭',
    'pages.buy.Failed': '失败',
    'pages.buy.SoldOut': '售完',
    'pages.buy.PlaceABid': '出价',
    'pages.buy.BuyNow': '购买',
    'pages.buy.BuyNowFor': '一口价：',
    'pages.buy.Offers': '报价',
    'pages.buy.Supply': '供应量',
    'pages.buy.TokenInfo': '代币信息',
    'pages.buy.ExternalLink': '外链',
    'pages.buy.TradingHistory': '交易记录',
    'pages.buy.MarketPlace': '市场',
    'pages.buy.FineArts': '艺术品',
    'pages.buy.descriptionLoading': '描述加载中 ...',
    'pages.buy.NameLoading': '名称加载中 ...',
    'pages.buy.loading': '加载中 ...',
    'pages.buy.TryAgain': '非常抱歉，我们似乎碰到了一些小问题，请重试',
    'pages.buy.DataUpdateFailed': '数据更新失败，请重试',
    'pages.buy.DealFailed': '交易未达成，请取回您的NFT',
    'pages.buy.SuccessfullyClaim': "交易未达成，您已取回您的NFT",
    'pages.buy.ClaimBidNFT': '取回您拍得的NFT',
    'pages.buy.BidAndClaimed': '您已拍得此NFT并取将其取回',
    'pages.buy.Cancel': '下架',
    'pages.buy.ConfirmCancel': '确认下架',
    'pages.buy.AreYouSure': '您确定要下架该商品吗？',
    'pages.buy.Yes': '是',
    'pages.buy.No': '否',
    'pages.buy.TransactionSuccess': '交易成功！',
    'pages.buy.AuctionCancelled': '此次拍卖仅在超过截止日期后失效',


    'pages.buy.TradeTable.Event': '事件',
    'pages.buy.TradeTable.Quantity': '数量',
    'pages.buy.TradeTable.Price': '价格',
    'pages.buy.TradeTable.From': '来源',
    'pages.buy.TradeTable.To': '去向',
    'pages.buy.TradeTable.Date': '时间',
    'pages.buy.TradeTable.Buy': '购买',
    'pages.buy.TradeTable.Bid': '出价',
    'pages.buy.TradeTable.Transfer': '转移',
    'pages.buy.TradeTable.Created': '创建',
    'pages.buy.TradeTable.Listed': '上架',

    "UIKit.Input.Upload.infoTip.FormatIncorrect": '暂时不支持您选择的文件格式，请重新选择',
    "UIKit.Input.Upload.infoTip.uploadImage": '上传图像',
    "UIKit.Input.Upload.infoTip.uploadVideo": '上传视频',
    "UIKit.Input.Upload.infoTip.requirement1": '支持 JPG, PNG, JPEG2000 格式',
    "UIKit.Input.Upload.infoTip.requirement2": '图片大小不大于 100MB, 推荐分辨率为262*262',
    "UIKit.Input.Upload.infoTip.image.requirement1": '支持 JPG, PNG, JPEG2000 格式',
    "UIKit.Input.Upload.infoTip.image.requirement2": '图片大小不大于 100MB, 推荐分辨率为262*262',
    "UIKit.Input.Upload.infoTip.video.requirement1": '支持 MP4 格式',
    "UIKit.Input.Upload.infoTip.video.requirement2": '图片大小不大于 100MB, 推荐分辨率为360*240',
    "UIKit.Input.Upload.infoTip.avatar.ChangeProfilePhoto": '更改用户头像',
    "UIKit.Input.Upload.infoTip.avatar.requirement1": '支持 JPG, PNG, JPEG2000 格式',
    "UIKit.Input.Upload.infoTip.avatar.requirement2": '图片大小不大于 100MB, 推荐分辨率为262*262',
    "UIKit.Input.Upload.infoTip.videoError": '文件大小或文件类型错误。 请检查并重试。',
    "UIKit.Input.Upload.infoTip.uploadImageAndVideo": '上传图像或视频',
    "UIKit.Input.Upload.infoTip.requirementImageANdVideo": '支持 JPG, PNG, JPEG2000, MP4 格式',
    "UIKit.Input.Upload.infoTip.image.requirementImageAndVideo": '图片或视频大小不大于 100MB, 图片推荐分辨率为262*262, 图片推荐分辨率为360*240',

    home: {
        banner1: '我们让创意更易于交易。',
        banner2: '一切为了粉丝，艺术家和收藏家。',
        Explore: '浏览',
        fast: '热门NFT',
        brand: '热门品牌',
        footerBanner: '在Fangible创建您独一无二的NFT',
        more: '查看更多',
        view: '浏览所有',
        cardTitle1: '市场',
        cardContent1: '艺术品、动漫、运动纪念品及其它NFT。',
        cardTitle2: '品牌',
        cardContent2: '众多品牌尽在 Fangible',

        "PopularItem.LoadingPrice": "正在加载价格 ...",
        "PopularItem.Price": "价格",
        "PopularItem.TopBid": "最高出价",
    },

    market: {
        myMarket: '我的市场',
        fineArts: '艺术品',
        sports: '运动纪念品',
        comics: '动漫',
        placeholder: "搜索物品或账户",
        Marketplace: "市场",
    },

    MarketPlace: {
        "CardItem.LoadingPrice": "正在加载价格",
        "CardItem.Price": "价格",
        "CardItem.TopBid": "最高出价",
        "CardItem.ShowMore": "显示更多信息",
        "CardItem.PlayVideo": "播放视频",
        "CardItem.ListenAudio": "播放音频",
    },

    Factory: {
        /* wrapperIntl("Factory.Generate") */
        "Generate": "创建NFT",
        "Build": "创建品牌",
        "List": "上架NFT",
        "GenerateYourNFT": "创建您的NFT",
        "GenerateDescription": "您不需要提前拥有一个商店或品牌，从此处即可开始创建您的NFT。",
        "BuildYourBrand": "创建您的品牌",
        "BuildDescription": "您可以创建一个合约，并根据该合约生成无限的NFT。",
        "ListYourNFT": "上架您的NFT",
        "ListDescription1": "如果您已经拥有就绪的代币合约，您可以直接点击此处售卖它们。",
        "ListDescription2": "仅可用于主网",
    },

    AirHome: {
        fineArts: '艺术品',
        sports: '运动纪念品',
        comics: '动漫',
        placeholder: "搜索物品、品牌或账户",
        Change: "更改品牌封面",
    },

    Brands: {
        placeholder: "搜索品牌名或品牌拥有者",
        "BrandCard.Ownedby": "拥有者：",
        "BrandCard.VisitStore": "浏览品牌商店",
    },

    ListNFTModal: {
        "ListYourNFT": "上架 NFT",
        "WhatAddress": "您在以太坊网络上的 ERC721 或 ERC1155 合约地址是什么？",
        "placeholder": "请输入您的 ERC721 或 ERC1155合约地址",
        "Cancel": "取消",
        "Submit": "提交",
        "LoadItems": "加载物品",
        "step1": "请输入您的合约地址",
        "step2": "上架您的物品",
    },

    TransferStatusModal: {
        "title1": "Bounce 请求授权",
        "content1": "请授权Bounce对您的代币进行操作",
        "title2": "正在等待确认",
        "content2": "请在您的钱包中确认此次交易",
        "title3": "正在处理",
        "content3": "请稍等",
        "title4": "Your BOT was Staked",
        "content4": "You can claim your principle",
        "title5": "Your BOT was Unstaked",
        "content5": "You successfully unstake your token",
        "title-1": "交易失败",
        "content-1": "此次交易失败且未被提交",
        "title-2": "取消创建池子",
        "content-2": "您取消了此次创建池子",
        /* "title0": "",
        "content0": "", */
        "title6": "成功!",
        "content6": "成功取回您的奖励",
        "title7": "成功!",
        "content7": "交易成功!",
        "Awaiting": "请稍等 ...",
        "Close": "关闭",
        "TryAgain": "重试",
    },

    PlaceBidModal: {
        "EnterAmount": "请输入数量",
        "Cancel": "取消",
        "SaleEndsIn": "剩余竞拍时间：",
        "days": "天",
        "hours": "小时",
        "minutes": "分钟",
        "PlaceAbid": "出价",
        "Checkout": "结算",
        "BuyNow": "立即购买",
    },

    BuyNowModal: {
        "YourBalance": "您的余额",
        "NameLoading": "名称加载中 ...",
        "Cancel": "取消",
        "Checkout": "结算",
    },

    FixedSwapBuyModal: {
        "BuyAmount": "购买数量",
        "Cancel": "取消",
        "Checkout": "结算",
    },

    ConnectWalletModal: {
        "ConnectWallet": "连接钱包",
        "PleaseWait": "请稍等...",
        "ToGetStarted": "使用本平台需要您连接一个钱包. 从以下选项中选择一个或从您的MetaMask中进行连接。如果您有疑问，请点击",
        "here": "此处",
        "ToLearn": "了解更多",
    },

    TableList: {
        "YourInformation": "您的信息",
        "ContractAddress": "合约地址",
        "ContractName": "合约地址",
        "ContactSymbol": "合约标识符",
        "TotalSupply": "总供应量",
    }, 
    
    MyProfile: {
        CommonHeader: {
            MyGallery: '我的展厅', 
            MyBrands: '我的品牌', 
            MyLiked: '我的收藏',
            MyActivities: '我的活动记录', 
            Settings: '设置',
            setting: '账户设置',
            Change: "更改封面",
        },

        AddCardItem: {
            AddNewNFT: '+ 添加NFT',
        },

        Category: {
            Items: '类别',
            Status: '状态',
        },

        "CardItem.CheckStatus": "查看状态",
        "CardItem.MakeUnlisted": "下架",
        "CardItem.Sell": "出售",
        "CardItem.All": "全部",
        "CardItem.OnSale": "售卖中",
        "CardItem.NotOnSale": "非卖品",
        "CardItem.TopBid": "最高出价",
        "CardItem.Price": "价格",
        "CardItem.InProcessOfCreation": "正在创建 ...",
        "CardItem.WaitingBlock": "等待区块确认 ...",

        "SettingAccountModal.Save": "保存",
        "SettingAccountModal.MyAccountSettings": "账户设置",
        "SettingAccountModal.UploadingFile": "正在上传文件 ...",
        "SettingAccountModal.UploadingData": "正在上传数据 ...",
        "SettingAccountModal.OnlySupports": "仅支持 JPG, PNG, JPEG2000 格式",
        "SettingAccountModal.UserName": "昵称",
        "SettingAccountModal.FullName": "用户名",
        "SettingAccountModal.BounceID": "Bounce ID",
        "SettingAccountModal.Email": "电子邮箱地址 (选填)",
        "SettingAccountModal.EmailPlaceholder": "请输入您的电子邮箱地址",
        "SettingAccountModal.ShortBio": "个人简介 (选填)",
        "SettingAccountModal.ShortBioPlaceholder": "请输入您的个人简介",
        "SettingAccountModal.Cancel": "取消",
        "SettingAccountModal.DataUpdateFailed": '数据更新失败，请重试',

        'MyGallery.GenerateNewNFTModal.GenerateNewNFT': '创建NFT',
        'MyGallery.GenerateNewNFTModal.Name': '名称',
        'MyGallery.GenerateNewNFTModal.Category': '类别',
        'MyGallery.GenerateNewNFTModal.Channel': '频道',
        'MyGallery.GenerateNewNFTModal.Standard': '标准',
        'MyGallery.GenerateNewNFTModal.Description': '描述',
        'MyGallery.GenerateNewNFTModal.RequiredAlert': 'RequiredAlert',
        'MyGallery.GenerateNewNFTModal.browseBrandPhoto': '浏览品牌图像',
        'MyGallery.GenerateNewNFTModal.Cancel': '取消',
        'MyGallery.GenerateNewNFTModal.Submit': '提交',
        'MyGallery.GenerateNewNFTModal.Supply': '供应量',
        'MyGallery.GenerateNewNFTModal.TryAgain': '请重新操作',
        'MyGallery.GenerateNewNFTModal.UploadingData': '正在上传数据...',
        'MyGallery.GenerateNewNFTModal.SuccessfullyGenerate': "恭喜您，您的NFT已经创建成功",
        'MyGallery.GenerateNewNFTModal.TryAgainNotice': '非常抱歉，我们似乎碰到了一些小问题，请重试',
        
        "MyGallery.MyNFT.DataUpdateFailed": '数据更新失败，请重试',

        /* wrapperIntl('MyProfile.MyGallery.SellNFT.EnglishAuction') */
        'MyGallery.SellNFT.MyGallery': '我的展厅',
        'MyGallery.SellNFT.ItemName': '物品名称',
        'MyGallery.SellNFT.Sell': '出售',
        'MyGallery.SellNFT.Price': '价格',
        'MyGallery.SellNFT.PriceNotice': "该NFT将持续出售直到您将其转移或取消本次售卖。",
        'MyGallery.SellNFT.Instructions1': "Bounce Collectible 是一个去中心化平台, 因此我们绝不会将您的物品转移给第三方。如果您是第一次出售加密收藏品，您需要完成以下两笔免手续费（需要gas费）的交易：",
        'MyGallery.SellNFT.Instructions2': "您需要初始化您的账户，完成后才能发起售卖，此操作仅需进行一次。",
        'MyGallery.SellNFT.Instructions3': "您需要授权Bounce在交易时对您的物品（或收藏集中的所有物品，如果该收藏集支持）进行操作。",
        'MyGallery.SellNFT.Minimumbid': '起拍价',
        'MyGallery.SellNFT.MinimumbidNotice': "起拍价对所有用户可见，您可以选择接受低于保留价但高于起拍价的出价。",
        'MyGallery.SellNFT.DirectPurchasePrice': '一口价',
        'MyGallery.SellNFT.DirectPurchaseNotice': "竞拍者可以以一口价直接购得该拍卖品。一口价必须高于起拍价和保留价。",
        'MyGallery.SellNFT.ReservePrice': '保留价',
        'MyGallery.SellNFT.ReservePriceNotice': "设定保留价会生成一个隐藏的限制，如果您没有收到等于或高于保留价的出价，您的拍卖品将不会售出。",
        'MyGallery.SellNFT.ExprirationDate': '截止时间',
        'MyGallery.SellNFT.ExprirationDateNotice': '您的拍卖将在此时间自动截止，出价最高者将赢得此次竞拍。',
        'MyGallery.SellNFT.SelectMethod': '请选择您的售卖方法',
        'MyGallery.SellNFT.SetPriceFeature': "定价为您心仪的价格",
        'MyGallery.SellNFT.EnglishAcutionFeature': "在您指定的时间内出售给出价最高者。",
        'MyGallery.SellNFT.InstantSale': "一口价出售",
        'MyGallery.SellNFT.EnglishAuction': "英式拍卖",

        /* wrapperIntl('MyProfile.MyGallery.Summary.ToFangible') */
        'MyGallery.Summary.Summary': "统计",
        'MyGallery.Summary.Listing': "挂单",
        'MyGallery.Summary.setPriceListingText': "您的物品将保持上架",
        'MyGallery.Summary.EnglishAuctionListingText': "您的物品将被拍卖，出价最高者将拍得您的物品，其出价必须高于",
        'MyGallery.Summary.days': "天",
        'MyGallery.Summary.Fees': "费用",
        'MyGallery.Summary.ToFangible': "由 Fangible 收取",
        'MyGallery.Summary.PostYourListing': "提交您的挂单",
        'MyGallery.Summary.ExpirationDate': "截止时间",

        'MyGallery.InputPrice.Price': "价格",
        'MyGallery.InputPrice.Amount': "数量",
        'MyGallery.InputPrice.Balance': "保有量",

        'MyGallery.InstructionsDropdown.Instructions': "声明",

        'MyGallery.DurationDropdown.str1': " ",
        'MyGallery.DurationDropdown.str2': "天",

        'MyBrands.ListCardItem.Create': "创建",

        /* wrapperIntl('MyProfile.MyBrands.updateTopBarImg.preview') */
        'MyBrands.updateTopBarImg.ChangeThemePhoto': "更改背景图片  ",
        'MyBrands.updateTopBarImg.infoTip1': "支持 JPG, PNG, JPEG2000 格式",
        'MyBrands.updateTopBarImg.infoTip2': "文件不大于 100MB,",
        'MyBrands.updateTopBarImg.infoTip3': "最佳",
        'MyBrands.updateTopBarImg.ApplyAndSave': "应用并保存",
        'MyBrands.updateTopBarImg.UploadingFile': "正在上传文件 ...",
        'MyBrands.updateTopBarImg.UploadError': "上传错误!",
        'MyBrands.updateTopBarImg.uploadImage': "上传图像",
        'MyBrands.updateTopBarImg.Reselect': "重新选择",
        'MyBrands.updateTopBarImg.preview': "预览",
        'MyBrands.updateTopBarImg.CropAgain': "请重新裁剪",

        "MyBrands.AddNewBrandsModal.Save": "保存",
        "MyBrands.AddNewBrandsModal.Cancel": "取消",
        "MyBrands.AddNewBrandsModal.UploadingFile": "正在上传文件 ...",
        "MyBrands.AddNewBrandsModal.UploadingData": "正在上传数据 ...",
        "MyBrands.AddNewBrandsModal.TryAgain": "请重新操作",
        "MyBrands.AddNewBrandsModal.BuildYourBrand": "创建品牌",
        "MyBrands.AddNewBrandsModal.BrandName": "品牌名称",
        "MyBrands.AddNewBrandsModal.Standard": "标准",
        "MyBrands.AddNewBrandsModal.StandardNotice": "请选择品牌使用的标准，该标准设定后无法更改，该品牌下的所有物品都将遵循该标准。",
        "MyBrands.AddNewBrandsModal.Symbol": "符号",
        "MyBrands.AddNewBrandsModal.Description": "品牌介绍",
        "MyBrands.AddNewBrandsModal.DescriptionPlaceHolder": "输入你的品牌介绍",
        "MyBrands.AddNewBrandsModal.browseBrandPhoto": '浏览品牌图像',
        "MyBrands.AddNewBrandsModal.OnlySupports": "仅支持 JPG, PNG, JPEG2000 格式",
        "MyBrands.AddNewBrandsModal.SuccessfullyBuild": "品牌创建成功",
        "MyBrands.AddNewBrandsModal.DataUpdateFailed": '数据更新失败，请重试',
        
        /* wrapperIntl('MyProfile.MyBrands.AddNewItemModal.Cancel') */
        "MyBrands.AddNewItemModal.OnlySupports": "仅支持 JPG, PNG, JPEG2000 格式",
        'MyBrands.AddNewItemModal.SuccessfullyGenerate': "恭喜您，您的NFT已经创建成功",
        'MyBrands.AddNewItemModal.TryAgainNotice': '非常抱歉，我们似乎碰到了一些小问题，请重试',
        "MyBrands.AddNewItemModal.UploadingData": "正在上传数据 ...",
        "MyBrands.AddNewItemModal.Submit": "提交",
        "MyBrands.AddNewItemModal.AddNewItem": "创建新物品",
        "MyBrands.AddNewItemModal.Name": "名称",
        "MyBrands.AddNewItemModal.Category": "种类",
        "MyBrands.AddNewItemModal.Channel": "频道",
        "MyBrands.AddNewItemModal.Standard": "标准",
        "MyBrands.AddNewItemModal.Supply": "供应量",
        "MyBrands.AddNewItemModal.Description": "描述",
        "MyBrands.AddNewItemModal.browseBrandPhoto": "浏览品牌图像",
        "MyBrands.AddNewItemModal.Cancel": "取消",

        "MyBrands.CardItem.Add": "添加",

        "MyBrands.BrandsByType.AllItems": "全部",
        "MyBrands.BrandsByType.Image": "图像",
        "MyBrands.BrandsByType.BackToBrands": "返回品牌合集",
        "MyBrands.BrandsByType.Change": "更改横幅图片",
        "MyBrands.BrandsByType.Edit": "修改品牌信息",
        "MyBrands.BrandsByType.EditYourBrand": "修改品牌信息",
        "MyBrands.BrandsByType.BrandName": "品牌名称",
        "MyBrands.BrandsByType.Description": "描述",
        "MyBrands.BrandsByType.DescribeYourBrand": "描述您的品牌",
        "MyBrands.BrandsByType.Cancel": "取消",
        
        /* wrapperIntl("MyProfile.MyActivities.TableList.Event") */
        "MyActivities.TableList.Event": "事件",
        "MyActivities.TableList.Item": "物品",
        "MyActivities.TableList.Quantity": "数量",
        "MyActivities.TableList.From": "来源",
        "MyActivities.TableList.To": "去向",
        "MyActivities.TableList.Date": "日期",
        "MyActivities.TableItem.Transfer": "转移",
        "MyActivities.TableItem.Created": "创建",
        "MyActivities.TableItem.about": "约",
        "MyActivities.TableItem.minutes": "分钟",
        "MyActivities.TableItem.minute": "分钟",
        "MyActivities.TableItem.hours": "小时",
        "MyActivities.TableItem.hour": "小时",
        "MyActivities.TableItem.days": "天",
        "MyActivities.TableItem.day": "天",
        }
    }

export default zh_CN